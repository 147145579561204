import { useI18n } from 'vue-i18n';
import devLog from './devLog';

export interface DocInterface {
  langs: string[];
  type: 'file' | 'link';
  mask?: string;
  needAccept?: boolean;
  acceptKey?: string;
  sourceDesc?: string;
  links?: { [key: string]: string };
}

export const docsInfo: {
  'cookie-policy': DocInterface;
  'privacy-notice': DocInterface;
  'terms-of-use': DocInterface;
} = {
  'privacy-notice': {
    type: 'file',
    langs: ['en'],
    mask: 'Privacy_Notice.pdf',
    needAccept: true,
    acceptKey: 'privacy_notice',
    sourceDesc: 'Privacy Notice',
  },
  'cookie-policy': {
    type: 'file',
    langs: ['en'],
    mask: 'cookie-policy.pdf',
  },
  'terms-of-use': {
    type: 'file',
    langs: ['en'],
    mask: 'terms-of-use.pdf',
    needAccept: true,
    acceptKey: 'terms_of_use',
    sourceDesc: 'Terms of use',
  },
};

export type keysOfDocs = keyof typeof docsInfo;
export const docsKeys = Object.keys(docsInfo) as keysOfDocs[];

const tryToReturnResult = (funcWithResult: () => string) => {
  let result = '';

  try {
    result = funcWithResult();

    if (!result) {
      return '#';
    }

    // return result
    return result;
  } catch (e: any) {
    console.error(e);
    return '#';
  }
};

export const getDoc = (docName: keyof typeof docsInfo, lang: string): string => {
  let currentLang = lang;
  const docInfo = docsInfo[docName];

  const logDocLangIsNotFound = () => {
    devLog.warn(`[getDoc]: ${docName}_${currentLang.toUpperCase()} is not found`);
  };

  if (!docInfo) {
    devLog.warn(`[getDoc]: document with name ${docName} is not found`);

    return '#';
  }

  // check that currentLang includes for that file
  if (!docInfo.langs.includes(currentLang)) {
    [currentLang] = docInfo.langs;
    devLog.warn(
      `[getDoc]: ${docName}_${lang.toUpperCase()} is not found.`,
      `Trying to get in ${currentLang.toUpperCase()}`,
    );
  }

  if (docInfo.type === 'link') {
    const { links } = docInfo;

    if (!links) {
      logDocLangIsNotFound();
      return '#';
    }

    return tryToReturnResult(() => links[currentLang]);
  }

  if (docInfo.type === 'file' || !docInfo.type) {
    const { mask } = docInfo;

    if (!mask) {
      logDocLangIsNotFound();
      return '#';
    }

    return tryToReturnResult(() => {
      const result = mask.replace('{lang}', currentLang);
      return `/docs/${result}`;
    });
  }

  return '#';
};

export const getTrHookGetDoc = () => {
  const { locale } = useI18n({ useScope: 'global' });

  return (docName: keyof typeof docsInfo) => getDoc(docName, locale.value);
};
